
































































































































































































































































































import CharacterLength from "@/components/CharacterLength/CharacterLength.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useCalculator, useTax } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  RequestCreateInputPph,
  ResponseListInvoiceARAvailableInputPph,
} from "@/models/interface/invoice.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  DataMasterCurrency,
  DataTax,
} from "@/models/interface/settings.interface";
import { contactServices } from "@/services/contact.service";
import { invoiceServices } from "@/services/invoice.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  formatterPercent,
  reverseFormatPercent,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

interface LoadingComponent {
  loadingFind: boolean;
  loadingBastNumber: boolean;
  download: boolean;
  loadingCustomerName: boolean;
  loadingBranch: boolean;
  loadingDownload: boolean;
  loadingPphType: boolean;
  loadingCurrency: boolean;
  loadingSubmit: boolean;
}

@Component({
  components: { CharacterLength },
  watch: {
    "$store.state.preferenceStore.dataPreference": {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    mode: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$store.state.preferenceStore.dataPreference.length > 0) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    currencyFrom: {
      immediate: true,
      deep: true,
      handler(newVal) {
        const params: RequestQueryParamsModel = {
          search: `fromCurrency.currencyCode~${newVal}_AND_toCurrency.currencyCode~${this.currencyTo}`,
        };
        settingsServices.listOfCurrency(params, "").then(response => {
          if (response.data[0]) {
            this.setDataForm({ rates: currencyFormat(response.data[0].rate) });
          } else if (newVal === this.currencyTo) {
            this.setDataForm({ rates: currencyFormat(1) });
          } else {
            this.setDataForm({ rates: currencyFormat(0) });
          }
        });
      },
    },
  },
})
export default class CreateInputPPh extends Vue {
  moment = moment;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  formatterPercent = formatterPercent;
  reverseFormatPercent = reverseFormatPercent;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  formFind!: WrappedFormUtils;
  form!: WrappedFormUtils;
  params: RequestQueryParamsModel = {};
  loading: LoadingComponent = {
    loadingFind: false,
    loadingBastNumber: false,
    download: false,
    loadingCustomerName: false,
    loadingBranch: false,
    loadingDownload: false,
    loadingPphType: false,
    loadingCurrency: false,
    loadingSubmit: false,
  };
  columnsTable = [
    {
      title: this.$t("lbl_invoice_ar_no"),
      dataIndex: "documentnumber",
      key: "documentnumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
      responsiveColInput: [
        {
          name: "pphAmount",
          placeholder: "",
          disabled: false,
          style: "width: 100%",
        },
      ],
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoicedate",
      key: "invoicedate",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_inovice_amount_dpp"),
      dataIndex: "invoiceamount",
      key: "invoiceamount",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_pph_outstanding"),
      dataIndex: "invoiceincometaxamountoutstanding",
      key: "invoiceincometaxamountoutstanding",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_pph_amount"),
      dataIndex: "pphAmount",
      key: "pphAmount",
      width: 150,
      scopedSlots: { customRender: "pphAmount" },
    },
  ];
  formRulesFind = {
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    pphType: {
      label: "lbl_pph_type",
      name: "pphType",
      placeholder: "lbl_pph_type_placeholder",
      decorator: [
        "pphType",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  formRules = {
    journalNumber: {
      label: "lbl_journal_number",
      decorator: ["journalNumber"],
    },
    status: {
      label: "lbl_status",
      decorator: ["status"],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: [
        "description",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rates: {
      label: "lbl_rate",
      name: "rates",
      placeholder: "lbl_rate_placeholder",
      decorator: [
        "rates",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    dateOfPph: {
      label: "lbl_date_pph",
      name: "dateOfPph",
      placeholder: "lbl_date_pph_placeholder",
      decorator: [
        "dateOfPph",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    date: {
      label: "lbl_date",
      name: "date",
      placeholder: "lbl_date_placeholder",
      decorator: [
        "date",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    pphNumber: {
      label: "lbl_pph_number",
      name: "pphNumber",
      placeholder: "lbl_pph_number_placeholder",
      decorator: [
        "pphNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    pphRate: {
      label: "lbl_pph_rate",
      name: "pphRate",
      placeholder: "lbl_pph_rate",
      decorator: [
        "pphRate",
        {
          initialValue: 0,
        },
      ],
    },
  };
  dataSource: ResponseListInvoiceARAvailableInputPph[] = [];
  dataCustomerName: ContactData[] = [];
  dataBranch: DataWarehouseBranch[] = [];
  dataPphType: DataTax[] = [];
  dataCurrency: DataMasterCurrency[] = [];

  optPphType: Array<Option<DataTax>> = [];

  branchId = "";
  pphTypeId = "";
  customerId = "";
  currencyId = "";
  idInputPph = "";
  journalNumber = "";
  currencyTo = "";
  currencyFrom = "";
  view = false;
  mode: string = Mode.CREATE;
  journal = {
    id: "",
    number: "",
  };

  beforeCreate(): void {
    this.formFind = this.$form.createForm(this, { name: "FindCreateInputPPh" });
    this.form = this.$form.createForm(this, { name: "CreateInputPPh" });
  }

  mounted() {
    this.getCustomerName = debounceProcess(this.getCustomerName, 200);
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getPphType = debounceProcess(this.getPphType, 400);
    this.getCurrency = debounceProcess(this.getCurrency, 400);

    this.getCustomerName("");
    this.getBranch("");
    this.getPphType("");
    this.getCurrency("");
    this.mode = this.$route.meta.mode;
    if (this.$route.meta.mode === Mode.VIEW) {
      this.view = true;
      this.idInputPph = this.$route.params.id;
      this.getDetailInputPph();
    }
  }

  get pphOutstandingTotal(): number {
    const { sum } = useCalculator();
    const source = [...this.dataSource].map<number>(item =>
      changeCurrencytoNumeric(item.invoiceincometaxamountoutstanding)
    );
    return sum(source);
  }

  get grossAmountTotal(): number {
    const { sum } = useCalculator();
    const source = [...this.dataSource].map<number>(item =>
      changeCurrencytoNumeric(item.invoiceamount)
    );
    return sum(source);
  }

  get pphAmountTotal(): number {
    const { sum } = useCalculator();
    const source = [...this.dataSource].map<number>(item =>
      changeCurrencytoNumeric(item.pphAmount)
    );
    return sum(source);
  }

  get totalBruto(): number {
    const { findPphBrutoTotal } = useTax();
    return findPphBrutoTotal(
      this.pphAmountTotal,
      this.form.getFieldValue("pphRate")
    );
  }

  setDataForm(values): void {
    for (const key in values) {
      this.form.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.form.setFieldsValue(values);
  }

  setDataFormFind(values): void {
    for (const key in values) {
      this.formFind.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.formFind.setFieldsValue(values);
  }

  handleChangeCurrency(idCurrency) {
    if (idCurrency) {
      this.currencyFrom = this.dataCurrency.find(
        dataFind => dataFind.id === idCurrency
      )?.currencyCode as string;
    } else {
      this.currencyFrom = "";
    }
  }

  getPphType(valueSearch: string) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `taxType~INCOME_TAX_RECEIVABLES`,
    };
    if (valueSearch)
      params.search = `code~*${valueSearch}*_OR_description~*${valueSearch}*_AND_taxType~INCOME_TAX_RECEIVABLES`;

    this.loading.loadingPphType = true;
    settingsServices
      .listOfTax(params, "")
      .then(response => {
        this.optPphType = response.data.map(item => ({
          key: item.id,
          label: item.code || "-",
          value: item.id,
          meta: item,
        }));
      })
      .finally(() => (this.loading.loadingPphType = false));
  }

  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }
  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `currencyCode~*${valueSearch}*`;
    this.loading.loadingCurrency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(response => (this.dataCurrency = response.data))
      .finally(() => (this.loading.loadingCurrency = false));
  }
  setBaseCurrencyCreate() {
    const tempObject = {
      feature_base_currency: "",
    };
    this.$store.state.preferenceStore.dataPreference.forEach(dataForeach => {
      if (dataForeach.key === "feature_base_currency") {
        tempObject.feature_base_currency = dataForeach.value;
      }
    });
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (tempObject.feature_base_currency) {
      params.search = `secureId~${tempObject.feature_base_currency}`;
      settingsServices.listOfMasterCurrency(params, "").then(response => {
        this.currencyTo = response.data[0].currencyCode as string;
        if (this.mode && this.mode.toLocaleLowerCase() === Mode.CREATE) {
          this.currencyFrom = response.data[0].currencyCode as string;
          this.setDataFormFind({ currency: response.data[0].id });
        }
      });
    }
  }
  getCustomerName(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: "customer~true_AND_active~true",
    };
    if (valueSearch)
      params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
    this.loading.loadingCustomerName = true;
    contactServices
      .listContactData(params)
      .then(response => (this.dataCustomerName = response.data))
      .finally(() => (this.loading.loadingCustomerName = false));
  }

  handleInput(value, key, _objectColInput, objectColInputName, onEvent) {
    if (onEvent === "onFocus") {
      this.dataSource[key][objectColInputName] = changeCurrencytoNumeric(value);
    }
    if (onEvent === "onChange") {
      this.dataSource[key][objectColInputName] = value;
    }
    if (onEvent === "onBlur") {
      this.dataSource[key][objectColInputName] = currencyFormat(value);
    }
    this.dataSource = this.dataSource.slice();
  }
  handleFind(): void {
    this.formFind.validateFields((err, values) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        branchId: values.branch,
        pphType: values.pphType,
        customerId: values.customerName,
        currencyId: values.currency,
      };
      this.loading.loadingFind = true;

      invoiceServices
        .getListInvoiceARAvailableForInputPph(params)
        .then(response => {
          this.branchId = values.branch;
          this.pphTypeId = values.pphType;
          this.customerId = values.customerName;
          this.currencyId = values.currency;

          this.dataSource = response.map((dataMap, index) => {
            return {
              ...dataMap,
              key: index,
              invoicedate: moment(dataMap.invoicedate).format(
                DEFAULT_DATE_FORMAT
              ),
              invoiceamount: currencyFormat(dataMap.invoiceamount),
              invoiceincometaxamountoutstanding: currencyFormat(
                dataMap.invoiceincometaxamountoutstanding
              ),
              invoiceoutstandingamount: currencyFormat(
                dataMap.invoiceoutstandingamount
              ),
              pphAmount: currencyFormat(0),
            };
          });
        })
        .finally(() => (this.loading.loadingFind = false));
    });
  }
  handleBack() {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push({ name: "tax.transaction.tax-income" });
      },
      onCancel() {
        return;
      },
    });
  }
  onSubmit(): void {
    if (this.branchId && this.pphTypeId && this.customerId && this.currencyId) {
      this.form.validateFields((err, values) => {
        if (err) return;
        const payload: RequestCreateInputPph = {
          branchId: this.branchId,
          pphTypeId: this.pphTypeId,
          customerId: this.customerId,
          currencyId: this.currencyId,
          date: moment(values.date).format("yyyy-MM-DD"),
          pphNumber: values.pphNumber,
          datePPHForm: moment(values.dateOfPph).format("yyyy-MM-DD"),
          rates: changeCurrencytoNumeric(values.rates),
          description: values.description,
          grossAmountTotal: this.grossAmountTotal,
          pphAmountTotal: this.pphAmountTotal,
          createLineRequestDTOS: this.dataSource.map(dataMap => {
            return {
              invoiceId: dataMap.invoicearid,
              invoiceARLineId: dataMap.invoicearlineid,
              invoiceAmount: changeCurrencytoNumeric(dataMap.invoiceamount),
              invoiceOutstandingAmount: changeCurrencytoNumeric(
                dataMap.invoiceoutstandingamount
              ),
              pphAmountOutstanding: changeCurrencytoNumeric(
                dataMap.invoiceincometaxamountoutstanding
              ),
              pphAmount: changeCurrencytoNumeric(dataMap.pphAmount),
            };
          }),
        };
        this.loading.loadingSubmit = true;
        invoiceServices
          .createInputPph(payload)
          .then(response => {
            this.$notification.success({
              message: Messages.CREATE_SUCCESS,
              description: `PPh Number: ${response.pphNumber}`,
              duration: 30,
            });
            this.$router.push({ name: "tax.transaction.tax-income" });
          })
          .finally(() => (this.loading.loadingSubmit = false));
      });
    } else {
      this.$notification.error({
        message: "Warning",
        description: "Input Branch, PPh Type, Customer Name, Currency first",
      });
    }
  }

  onBlur(event, form: string) {
    if (event.target.value) {
      let dataobj = {} as { [props: string]: string };
      dataobj[form] = currencyFormat(event.target.value);
      this.form.setFieldsValue(dataobj);
    }
  }

  onFocus(event, form: string) {
    if (event.target.value) {
      let dataobj = {} as { [props: string]: number };
      dataobj[form] = changeCurrencytoNumeric(event.target.value);
      this.form.setFieldsValue(dataobj);
    }
  }

  getDetailInputPph() {
    invoiceServices.getDetailInputPph(this.idInputPph).then(response => {
      this.form.setFieldsValue({
        status: response.status,
        date: moment(response.date, DEFAULT_DATE_FORMAT),
        pphNumber: response.pphNumber,
        dateOfPph: moment(response.datePPHForm, DEFAULT_DATE_FORMAT),
        rates: currencyFormat(response.rates),
        pphRate: response.pphRate,
        description: response.description,
      });
      this.formFind.setFieldsValue({
        branch: response.branchName,
        pphType: response.pphType,
        customerName: response.customerName,
        currency: response.currency,
      });
      this.journal = {
        id: response.journalId,
        number: response.journalNumber,
      };
      this.dataSource = response.inputPPHDetailLineResponseDTOS.map(dataMap => {
        return {
          documentnumber: dataMap.invoiceNumber,
          invoicedate: dataMap.invoiceDate,
          invoicearid: dataMap.invoiceId,
          invoicearlineid: dataMap.invoicearlineid,
          invoiceamount: currencyFormat(dataMap.invoiceAmount),
          invoiceoutstandingamount: currencyFormat(
            dataMap.invoiceOutstandingAmount
          ),
          invoiceincometaxamountoutstanding: currencyFormat(
            dataMap.pphAmountOutstanding
          ),
          pphAmount: currencyFormat(dataMap.inputPPHAmount),
          disabledInput: true,
        };
      });
    });
  }

  onChangePphType(value = ""): void {
    const pph = this.optPphType.find(item => item.value === value);

    // set pph rate
    this.form.setFieldsValue({
      pphType: value,
      pphRate: pph?.meta?.rate || 0,
    });
  }
}
