var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_tax_income") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.formFind,
            "wrapper-col": { span: 12 },
            "label-col": { span: 6 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleFind.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRulesFind.branch.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRulesFind.branch.decorator,
                              expression: "formRulesFind.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRulesFind.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRulesFind.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingBranch,
                            allowClear: true,
                            disabled: _vm.view
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRulesFind.pphType.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRulesFind.pphType.decorator,
                              expression: "formRulesFind.pphType.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRulesFind.pphType.name,
                            placeholder: _vm.$t(
                              _vm.formRulesFind.pphType.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingPphType,
                            allowClear: true,
                            disabled: _vm.view
                          },
                          on: {
                            search: function(value) {
                              return _vm.getPphType(value)
                            },
                            change: _vm.onChangePphType
                          }
                        },
                        _vm._l(_vm.optPphType, function(data) {
                          return _c(
                            "a-select-option",
                            { key: data.key, attrs: { value: data.value } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.label) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.label) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRulesFind.customerName.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRulesFind.customerName.decorator,
                              expression: "formRulesFind.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRulesFind.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRulesFind.customerName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingCustomerName,
                            allowClear: true,
                            disabled: _vm.view
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCustomerName(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCustomerName, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.fullName
                                            ? data.fullName.replace("null", "")
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.fullName
                                          ? data.fullName.replace("null", "")
                                          : "-"
                                      ) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRulesFind.currency.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRulesFind.currency.decorator,
                              expression: "formRulesFind.currency.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRulesFind.currency.name,
                            placeholder: _vm.$t(
                              _vm.formRulesFind.currency.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingCurrency,
                            allowClear: true,
                            disabled: _vm.view
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCurrency(value)
                            },
                            change: _vm.handleChangeCurrency
                          }
                        },
                        _vm._l(_vm.dataCurrency, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.currencyCode) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s(data.currencyCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "html-type": "submit",
                        type: "primary",
                        loading: _vm.loading.loadingFind,
                        disabled: _vm.view
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            "wrapper-col": { span: 12 },
            "label-col": { span: 6 }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  !!_vm.journal.id
                    ? _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.journalNumber.label)
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "generaljournal.detail",
                                  params: { id: _vm.journal.id }
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.journal.number) + " ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.date.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.date.decorator,
                            expression: "formRules.date.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.date.name,
                          placeholder: _vm.$t(_vm.formRules.date.placeholder),
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: _vm.view
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.pphNumber.label) } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.pphNumber.decorator,
                            expression: "formRules.pphNumber.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.pphNumber.name,
                          placeholder: _vm.$t(
                            _vm.formRules.pphNumber.placeholder
                          ),
                          disabled: _vm.view
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.dateOfPph.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.dateOfPph.decorator,
                            expression: "formRules.dateOfPph.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.dateOfPph.name,
                          placeholder: _vm.$t(
                            _vm.formRules.dateOfPph.placeholder
                          ),
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: _vm.view
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.rates.label) } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.rates.decorator,
                            expression: "formRules.rates.decorator"
                          }
                        ],
                        attrs: {
                          autocomplete: "off",
                          name: _vm.formRules.rates.name,
                          placeholder: _vm.$t(_vm.formRules.rates.placeholder),
                          disabled: _vm.view
                        },
                        on: {
                          blur: function($event) {
                            return _vm.onBlur($event, "rates")
                          },
                          focus: function($event) {
                            return _vm.onFocus($event, "rates")
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.description.label) }
                    },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.description.decorator,
                            expression: "formRules.description.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.description.name,
                          placeholder: _vm.$t(
                            _vm.formRules.description.placeholder
                          ),
                          "auto-size": { minRows: 3, maxRows: 5 },
                          disabled: _vm.view
                        }
                      }),
                      _c("character-length", {
                        attrs: { value: _vm.form.getFieldValue("description") }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.pphRate.label) } },
                    [
                      _c("a-input-number", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.pphRate.decorator,
                            expression: "formRules.pphRate.decorator"
                          }
                        ],
                        attrs: {
                          formatter: _vm.formatterPercent,
                          parser: _vm.reverseFormatPercent,
                          disabled: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.status.label) } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.status.decorator,
                            expression: "formRules.status.decorator"
                          }
                        ],
                        attrs: { disabled: "" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  size: "small",
                  dataSource: _vm.dataSource,
                  columns: _vm.columnsTable,
                  paginationcustom: false,
                  defaultPagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", { total: total })
                    },
                    showSizeChanger: true,
                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
                  },
                  scroll: { x: "calc(700px + 50%)" },
                  loading: _vm.loading.loadingFind,
                  handleInput: _vm.handleInput
                },
                scopedSlots: _vm._u([
                  {
                    key: "custom-footer",
                    fn: function() {
                      return [
                        _c(
                          "a-row",
                          {
                            staticClass: "flex-column align-items-md-end",
                            attrs: { type: "flex" }
                          },
                          [
                            _c(
                              "a-col",
                              { attrs: { xs: { span: 24 }, lg: { span: 10 } } },
                              [
                                _c(
                                  "table",
                                  {
                                    attrs: {
                                      "aria-describedby": "footer table"
                                    }
                                  },
                                  [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_total_brutto"))
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(_vm.totalBruto)
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "lbl_pph_outstanding_total"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.pphOutstandingTotal
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("lbl_invoice_amount_total")
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.grossAmountTotal
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_pph_amount_total"))
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.pphAmountTotal
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: { "margin-top": "2rem" },
          attrs: { type: "flex", justify: "end" }
        },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleBack } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
              ]),
              !_vm.view && _vm.$can("create", "input-pph")
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.loadingSubmit
                      },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }